import cdiLocationCache from '@/cache/cdiLocation.cache'

export const paymentStore = {
	state: {
		name: 'payment',
		title: 'Payment',
		titlePlural: 'Payments',
		nav: {
			base: 'payment',
			newView: true,
			views: [
				{view: 'details', label: 'Details'}
			]
		},
		search: {
			endPoint: 'payments',
			formFields: {
				company: {
					val: '',
					qsField: '[numbers][p.company_id]',
					label: 'Company',
					component: 'SearchTypeaheadInput',
					cdiType: 'company'
				},
				check: {
					val: '',
					qsField: '[numbers][check_num]',
					label: 'Check',
					component: 'SearchTextInput'
				},
				paymentDate: {
					val: '',
					qsField: '[dates][payment_date]',
					label: 'Payment Date',
					component: 'SearchDateInput'
				},
				trackerID: {
					val: '',
					qsField: '[numbers][t.tracker_id]',
					label: 'Order',
					component: 'SearchTextInput'
				},
                searchIndex: {val: 0, qsField: '[search_index]'},
			},
			resultFields: {
				payment_id: {
					header: 'ID',
					link: 'payment',
				},
				tracker_id: {
					header: 'Order',
					link: '',
				},
				check_num: {
					header: 'Check #',
					link: ''
				},
				company_id: {
					header: 'Company',
					link: 'company',
					textField: 'company_name'
				},
				amount: {
					header: 'Amount',
					link: ''
				},
				balance: {
					header: 'Balance',
					link: ''
				},
				payment_date: {
					header: 'Date'
				}
			}
		},
		object: {
			fields: [
				{
					name: 'payment_id',
					label: 'Payment ID',
					component: 'DetailsStatic'
				},
				{
					name: 'tracker_id',
					label: 'Order',
					component: 'DetailsStatic'
				},
				{
					name: 'company_name',
					label: 'Company',
					component: 'DetailsStatic'
				},
				{
					name: 'deposit_location_id',
					label: 'Deposit Location',
					component: 'DetailsSelect',
					options: cdiLocationCache.CDI_LOCATIONS
				},
				{
					name: 'payment_date',
					label: 'Payment Date',
					component: 'DetailsDate',
				},
				{
					name: 'memo',
					label: 'Memo',
					component: 'DetailsText'
				},
				{
					name: 'check_num',
					label: 'Check #',
					component: 'DetailsText'
				},
				{
					name: 'amount',
					label: 'Amount',
					component: 'DetailsText'
				},
				{
					name: 'payment_term',
					label: 'Terms',
					component: 'DetailsStatic'
				},
				{
					name: 'invoices_total',
					label: 'Payments',
					component: 'DetailsStatic'
				},
				{
					name: 'credits_total',
					label: 'Credits',
					component: 'DetailsStatic'
				},
				{
					name: 'balance_total',
					label: 'Balance',
					component: 'DetailsStatic'
				}
			]
		}
	}
}